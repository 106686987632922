// import i18n from "@/plugins/i18n";
import _ from "lodash";
import { validText, emailRegex, validPhone } from "./validators.js";

const validateTextField = (params, error, field) => {
  let result = 0;
  let validationStatus = null;
  let newError = _.clone(error);
  if (!params.data["status"] || !params.data["status"] === "success") {
    const rowId = params.data["rowId"];
    const errorKey = rowId + field;
    let hasErrors = false;
    if (
      !params.value ||
      !validText(params.value) ||
      (params.data["status"] === "error" &&
        params.data["errorValue"] === params.value)
    ) {
      if (!error.includes(errorKey)) {
        newError.push(errorKey);
      }
      hasErrors = true;
      validationStatus = false;
      result = 2;
    } else {
      let array = newError.filter(item => item !== errorKey);
      newError = array;
      hasErrors = newError.some(error => error.startsWith(rowId));
      validationStatus = !hasErrors;
    }
  } else {
    result = 1;
  }
  return {
    value: result,
    error: newError,
    validationStatus: validationStatus
  };
};

const validateSelect = (params, error, options, field) => {
  let result = 0;
  let newError = _.clone(error);
  let validationStatus = null;

  if (!params.data["status"] || !params.data["status"] === "success") {
    const rowId = params.data["rowId"];
    const errorKey = rowId + field;
    let hasErrors = false;

    if (!options.includes(params.value) || !params.value) {
      if (!newError.includes(errorKey)) {
        newError.push(errorKey);
      }
      hasErrors = true;
      validationStatus = false;
      result = 2;
    } else {
      let array = newError.filter(item => item !== errorKey);
      newError = array;
      hasErrors = newError.some(error => error.startsWith(rowId));
      validationStatus = !hasErrors;
    }
  } else {
    result = 1;
  }
  return {
    value: result,
    error: newError,
    validationStatus: validationStatus
  };
};

const validateBoolean = (params, error, field) => {
  let newError = _.clone(error);
  let validationStatus = null;
  let result = 0;

  if (!params.data["status"] || !params.data["status"] === "success") {
    const rowId = params.data["rowId"];
    const errorKey = rowId + field;
    let hasErrors = false;

    if ((params.value !== "YES" && params.value !== "NO") || !params.value) {
      if (!newError.includes(errorKey)) {
        newError.push(errorKey);
      }
      hasErrors = true;
      validationStatus = false;
      result = 2;
    } else {
      let array = newError.filter(item => item !== errorKey);
      newError = array;
      hasErrors = newError.some(error => error.startsWith(rowId));
      validationStatus = !hasErrors;
    }
  } else {
    result = 1;
  }

  return {
    value: result,
    error: newError,
    validationStatus: validationStatus
  };
};

const validateEmail = (params, error, options, field, subparam) => {
  let newError = _.clone(error);
  let validationStatus = null;
  let result = 0;
  let exists = null;
  if (!params.data["status"] || params.data["status"] !== "success") {
    const rowId = params.data["rowId"];
    const errorKey = rowId + field;
    let hasErrors = false;

    exists = false;
    _.each(options, email => {
      const emailValue = email[subparam] ? email[subparam].toLowerCase() : "";
      const paramValue = params.value ? params.value.toLowerCase() : "";
      if (
        emailValue.toLowerCase() === paramValue.toLowerCase() &&
        email.order !== rowId
      ) {
        exists = true;
      }
    });
    if (
      !emailRegex.test(params.value) ||
      options.includes(params.value) ||
      exists ||
      (params.data["status"] === "error" &&
        params.data["errorValue"] === params.value.toLowerCase())
    ) {
      if (!newError.includes(errorKey)) {
        newError.push(errorKey);
      }
      hasErrors = true;
      validationStatus = false;
      result = 2;
    } else {
      let array = newError.filter(item => item !== errorKey);
      newError = array;
      hasErrors = newError.some(error => error.startsWith(rowId));
      validationStatus = !hasErrors;
    }
  } else {
    result = 1;
  }
  return {
    value: result,
    error: newError,
    validationStatus: validationStatus,
    exists: exists
  };
};

const validateProfile = (params, error, field) => {
  let newError = _.clone(error);
  let validationStatus = null;
  let result = 0;
  if (!params.data["status"] || !params.data["status"] === "success") {
    const rowId = params.data["rowId"];
    const errorKey = rowId + field;
    let hasErrors = false;

    if (
      (params.value !== "YES" && params.value !== "NO") ||
      params.value === "" ||
      (params.data["Perfil - Administrador"] === "NO" &&
        params.data["Perfil - Operador"] === "NO" &&
        params.data["Perfil - Usuario"] === "NO")
    ) {
      if (!newError.includes(errorKey)) {
        newError.push(errorKey);
      }
      hasErrors = true;
      validationStatus = false;
      result = 2;
    } else {
      let array = newError.filter(item => item !== errorKey);
      newError = array;
      hasErrors = newError.some(error => error.startsWith(rowId));
      validationStatus = !hasErrors;
    }
  } else {
    result = 1;
  }

  return {
    value: result,
    error: newError,
    validationStatus: validationStatus
  };
};

const validatePhone = (params, error, field) => {
  let newError = _.clone(error);
  let validationStatus = null;
  let result = 0;
  if (!params.data["status"] || !params.data["status"] === "success") {
    const rowId = params.data["rowId"];
    const errorKey = rowId + field;
    let hasErrors = false;

    if (params.value) {
      if (!validPhone(params.value)) {
        if (!newError.includes(errorKey)) {
          newError.push(errorKey);
        }
        hasErrors = true;
        validationStatus = false;
        result = 2;
      }
    }

    let array = newError.filter(item => item !== errorKey);
    newError = array;
    hasErrors = newError.some(error => error.startsWith(rowId));
    validationStatus = !hasErrors;
  } else {
    result = 1;
  }

  return {
    value: result,
    error: newError,
    validationStatus: validationStatus
  };
};

export {
  validateTextField,
  validateSelect,
  validateBoolean,
  validateEmail,
  validateProfile,
  validatePhone
};
