<template>
  <div>
    <h4 style="margin-bottom: 20px;">
      {{ this.$t("admin.excel.users.title") }}
    </h4>
    <div style="margin-bottom: 10px;">
      <button
        style="margin-right: 5px;"
        class="btn ml-2 font-weight-bold btn-success"
        :disabled="isSubmitting || validated"
        @click="send"
      >
        {{ this.$t("admin.excel.button_send") }}
      </button>
      <label
        :style="{
          float: 'right',
          color: this.error.length > 0 ? 'red' : 'black'
        }"
        >{{
          this.$t("admin.excel.count_errors") + " " + this.error.length
        }}</label
      >
      <label>
        {{
          this.$t("admin.excel.request_sent") +
            " " +
            ((this.responseCount / this.totalRows) * 100).toFixed(0) +
            "%"
        }}</label
      >

      <div class="col-3">
        <label>{{ this.$t("admin.excel.date.title") }}</label>
        <input
          class="form-control"
          type="date"
          v-model="date"
          lang="es"
          :min="dateTomorrow"
          :style="{ borderColor: dateIsValid ? 'initial' : 'red' }"
        />
      </div>
    </div>
    <ag-grid-vue
      ref="agGrid"
      style="width: 100%;"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      domLayout="autoHeight"
      rowSelection="multiple"
      @grid-ready="onGridReady"
      @selectionChanged="onSelectionChanged"
    >
    </ag-grid-vue>
    <div class="col-4 d-flex align-items-start mt-5">
      <button
        class="btn mr-2 font-weight-bold btn-sm btn-primary"
        @click="addRow"
      >
        {{ $t("admin.excel.button_add_row") }}
      </button>
      <button
        class="btn font-weight-bold btn-sm btn-danger"
        @click="deleteSelectedRows"
      >
        {{
          $t("assets.buttons.delete_users", {
            departments: selectedRowCount == 0 ? "" : selectedRowCount
          })
        }}
      </button>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import Autocomplete from "@/components/form/Autocomplete.component";
import { validText, emailRegex } from "@/utils/validators.js";
import {
  validateTextField,
  validateSelect,
  validateBoolean,
  validateEmail,
  validateProfile,
  validatePhone
} from "@/utils/excel-validators.js";

import { getTimezones } from "@/api/misc.api";
import {
  getDepartments,
  sendExcelUsersData
} from "@/api/admin/stakeholders.api";
import axios from "axios";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UsersExcel",
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    Autocomplete
  },
  data() {
    return {
      columnDefs: [],
      rowData: null,
      originalRowData: null,
      error: [],
      date: this.getTomorrowDate(),
      dateTomorrow: this.getTomorrowDate(),
      timeZones: null,
      departments: null,
      departmentsName: [],
      emails: [],
      logins: [],
      emailExists: false,
      loginExists: false,
      languages: null,
      responseCount: 0,
      errorCount: 0,
      successCount: 0,
      totalRows: 0,
      isSubmitting: false,
      selectedRowCount: 0
    };
  },
  methods: {
    ...mapActions("Admin", ["loadCompanySelected", "loadUsers"]),
    validationStatusRenderer(params) {
      let tick = `<i class="fa fa-check" aria-hidden="true"></i>`;
      let cross = `<i class="fa fa-times" aria-hidden="true"></i>`;
      let icon = params.value.lastValidation === true ? tick : cross;
      return icon;
    },
    setupColumnDefs() {
      this.columnDefs = [
        {
          headerName: "",
          field: "validationStatus",
          cellDataType: "text",
          width: 70,
          sortable: true,
          cellRenderer: params => {
            if (
              !params.data["validationStatus"] ||
              params.data["status"] === "error"
            ) {
              return `<i class="fa fa-times text-danger" aria-hidden="true"></i>`;
            } else {
              return `<i class="text-success fa fa-check" aria-hidden="true"></i>`;
            }
          },
          valueGetter: params =>
            params.data["validationStatus"] ? "valid" : "invalid"
        },
        {
          headerName: this.$t("admin.excel.user.header_name"),
          field: this.$t("excel_headers.name"),
          width: 150,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          cellDataType: "text",
          filter: "agTextColumnFilter",
          cellStyle: params => {
            let result = validateTextField(params, this.error, "Name");
            this.error = result.error;
            if (result.validationStatus != null) {
              params.data["validationStatus"] = result.validationStatus;
            }

            if (result.value == 0) {
              return { backgroundColor: "white" };
            } else if (result.value == 1) {
              return { backgroundColor: "#bdecb6" };
            } else if (result.value == 2) {
              return { backgroundColor: "#FF6961" };
            }
          },
          tooltipValueGetter: params => {
            if (!params.value || !validText(params.value)) {
              return this.$t("errors.format");
            }
            if (
              params.data["status"] === "error" &&
              params.data["errorValue"] === params.value
            ) {
              return this.$t("errors." + params.data["errorCode"]);
            }
            return null;
          },
          onCellValueChanged: params => {
            if (params.data["status"] === "error") {
              const row = _.find(this.rowData, {
                rowId: params.data["rowId"]
              });

              if (row) {
                row.status = "modified";
              }
            }
            this.setupColumnDefs();
          },
          editable: true
        },
        {
          headerName: this.$t("admin.excel.user.header_surname"),
          field: this.$t("excel_headers.surname"),
          width: 150,
          cellDataType: "text",
          filter: "agTextColumnFilter",
          cellStyle: params => {
            let result = validateTextField(params, this.error, "Surname");
            this.error = result.error;
            if (result.validationStatus != null) {
              params.data["validationStatus"] = result.validationStatus;
            }

            if (result.value == 0) {
              return { backgroundColor: "white" };
            } else if (result.value == 1) {
              return { backgroundColor: "#bdecb6" };
            } else if (result.value == 2) {
              return { backgroundColor: "#FF6961" };
            }
          },
          tooltipValueGetter: params => {
            if (!params.value || !validText(params.value)) {
              return this.$t("errors.format");
            }
            if (
              params.data["status"] === "error" &&
              params.data["errorValue"] === params.value
            ) {
              return this.$t("errors." + params.data["errorCode"]);
            }
            return null;
          },
          onCellValueChanged: params => {
            if (params.data["status"] === "error") {
              const row = _.find(this.rowData, {
                rowId: params.data["rowId"]
              });

              if (row) {
                row.status = "modified";
              }
            }
            this.setupColumnDefs();
          },
          editable: true
        },
        {
          headerName: this.$t("admin.excel.user.header_department"),
          field: this.$t("excel_headers.department"),
          width: 150,
          cellDataType: "text",
          cellEditor: "Autocomplete",
          cellEditorParams: { values: this.departmentsName },
          filter: "agTextColumnFilter",
          cellStyle: params => {
            let result = validateSelect(
              params,
              this.error,
              this.departmentsName,
              "Department"
            );
            this.error = result.error;
            if (result.validationStatus != null) {
              params.data["validationStatus"] = result.validationStatus;
            }

            if (result.value == 0) {
              return { backgroundColor: "white" };
            } else if (result.value == 1) {
              return { backgroundColor: "#bdecb6" };
            } else if (result.value == 2) {
              return { backgroundColor: "#FF6961" };
            }
          },
          tooltipValueGetter: params => {
            if (!this.departmentsName.includes(params.value) || !params.value) {
              return this.$t("errors.ND1030");
            }
            return null;
          },
          editable: true
        },
        {
          headerName: this.$t("admin.excel.user.header_isManager"),
          field: this.$t("excel_headers.isManager"),
          width: 90,
          cellDataType: "text",
          cellEditor: "agSelectCellEditor",
          cellEditorParams: { values: ["YES", "NO"] },
          filter: "agTextColumnFilter",
          cellStyle: params => {
            let result = validateBoolean(params, this.error, "isManager");
            this.error = result.error;
            if (result.validationStatus != null) {
              params.data["validationStatus"] = result.validationStatus;
            }

            if (result.value == 0) {
              return { backgroundColor: "white" };
            } else if (result.value == 1) {
              return { backgroundColor: "#bdecb6" };
            } else if (result.value == 2) {
              return { backgroundColor: "#FF6961" };
            }
          },
          tooltipValueGetter: params => {
            if (
              (params.value !== "YES" && params.value !== "NO") ||
              !params.value
            ) {
              return this.$t("errors.format");
            }
            return null;
          },
          editable: true
        },
        {
          headerName: this.$t("admin.excel.user.header_position"),
          field: this.$t("excel_headers.position"),
          width: 130,
          cellDataType: "text",
          filter: "agTextColumnFilter",
          // cellStyle: params => {
          //   let result = validateTextField(params, this.error, "Position", false);
          //   this.error = result.error;
          //   if (result.validationStatus != null) {
          //     params.data["validationStatus"] = result.validationStatus;
          //   }

          //   if (result.value == 0) {
          //     return { backgroundColor: "white" };
          //   } else if (result.value == 1) {
          //     return { backgroundColor: "#bdecb6" };
          //   } else if (result.value == 2) {
          //     return { backgroundColor: "#FF6961" };
          //   }
          // },
          tooltipValueGetter: params => {
            if (!validText(params.value)) {
              return this.$t("errors.format");
            }
            return null;
          },
          editable: true
        },
        {
          headerName: this.$t("admin.excel.user.header_user_email"),
          field: this.$t("excel_headers.userEmail"),
          width: 150,
          cellDataType: "text",
          filter: "agTextColumnFilter",
          cellStyle: params => {
            let result = validateEmail(
              params,
              this.error,
              this.emails,
              "User Email",
              "email"
            );
            this.error = result.error;
            if (result.validationStatus != null) {
              params.data["validationStatus"] = result.validationStatus;
            }

            if (result.exists != null) {
              this.emailExists = result.exists;
            }

            if (result.value == 0) {
              return { backgroundColor: "white" };
            } else if (result.value == 1) {
              return { backgroundColor: "#bdecb6" };
            } else if (result.value == 2) {
              return { backgroundColor: "#FF6961" };
            }
          },
          tooltipValueGetter: params => {
            if (!emailRegex.test(params.value)) {
              return this.$t("errors.ND1041");
            }
            if (this.emailExists) {
              return this.$t("excel.users.email_repeated");
            }
            if (
              params.data["status"] === "error" &&
              params.data["errorValue"] === params.value.toLowerCase()
            ) {
              return this.$t(`errors.${params.data["errorCode"]}`);
            }
            return null;
          },
          onCellValueChanged: params => {
            if (params.data["status"] === "error") {
              const row = _.find(this.rowData, {
                rowId: params.data["rowId"]
              });

              if (row) {
                row.status = "modified";
              }
            }
            this.updateEmails();
            this.setupColumnDefs();
          },
          editable: true
        },
        {
          headerName: this.$t("admin.excel.user.header_profile_admin"),
          field: this.$t("excel_headers.profileAdmin"),
          width: 150,
          cellDataType: "text",
          filter: "agTextColumnFilter",
          cellEditor: "agSelectCellEditor",
          cellEditorParams: { values: ["YES", "NO"] },
          cellStyle: params => {
            let result = validateProfile(
              params,
              this.error,
              "Perfil - Administrador"
            );
            this.error = result.error;
            if (result.validationStatus != null) {
              params.data["validationStatus"] = result.validationStatus;
            }

            if (result.value == 0) {
              return { backgroundColor: "white" };
            } else if (result.value == 1) {
              return { backgroundColor: "#bdecb6" };
            } else if (result.value == 2) {
              return { backgroundColor: "#FF6961" };
            }
          },
          tooltipValueGetter: params => {
            if (params.value !== "YES" && params.value !== "NO") {
              return this.$t("errors.format");
            }
            if (
              params.data["Perfil - Administrador"] === "NO" &&
              params.data["Perfil - Operador"] === "NO" &&
              params.data["Perfil - Usuario"] === "NO"
            ) {
              return this.$t("errors.ND1006");
            }
            return null;
          },
          onCellValueChanged: () => {
            this.setupColumnDefs();
          },
          editable: true
        },
        {
          headerName: this.$t("admin.excel.user.header_profile_operator"),
          field: this.$t("excel_headers.profileOperator"),
          width: 150,
          cellDataType: "text",
          filter: "agTextColumnFilter",
          cellEditor: "agSelectCellEditor",
          cellEditorParams: { values: ["YES", "NO"] },
          cellStyle: params => {
            let result = validateProfile(
              params,
              this.error,
              "Perfil - Operador"
            );
            this.error = result.error;
            if (result.validationStatus != null) {
              params.data["validationStatus"] = result.validationStatus;
            }

            if (result.value == 0) {
              return { backgroundColor: "white" };
            } else if (result.value == 1) {
              return { backgroundColor: "#bdecb6" };
            } else if (result.value == 2) {
              return { backgroundColor: "#FF6961" };
            }
          },
          tooltipValueGetter: params => {
            if (params.value !== "YES" && params.value !== "NO") {
              return this.$t("error.format");
            }
            if (
              params.data["Perfil - Administrador"] === "NO" &&
              params.data["Perfil - Operador"] === "NO" &&
              params.data["Perfil - Usuario"] === "NO"
            ) {
              return this.$t("errors.ND1006");
            }
            return null;
          },
          onCellValueChanged: () => {
            this.setupColumnDefs();
          },
          editable: true
        },
        {
          headerName: this.$t("admin.excel.user.header_profile_user"),
          field: this.$t("excel_headers.profileUser"),
          width: 150,
          cellDataType: "text",
          filter: "agTextColumnFilter",
          cellEditor: "agSelectCellEditor",
          cellEditorParams: { values: ["YES", "NO"] },
          cellStyle: params => {
            let result = validateProfile(
              params,
              this.error,
              "Perfil - Usuario"
            );
            this.error = result.error;
            if (result.validationStatus != null) {
              params.data["validationStatus"] = result.validationStatus;
            }

            if (result.value == 0) {
              return { backgroundColor: "white" };
            } else if (result.value == 1) {
              return { backgroundColor: "#bdecb6" };
            } else if (result.value == 2) {
              return { backgroundColor: "#FF6961" };
            }
          },
          tooltipValueGetter: params => {
            if (params.value !== "YES" && params.value !== "NO") {
              return this.$t("errors.format");
            }
            if (
              params.data["Perfil - Administrador"] === "NO" &&
              params.data["Perfil - Operador"] === "NO" &&
              params.data["Perfil - Usuario"] === "NO"
            ) {
              return this.$t("errors.ND1006");
            }
            return null;
          },
          onCellValueChanged: () => {
            this.setupColumnDefs();
          },
          editable: true
        },
        {
          headerName: this.$t("admin.excel.user.header_timezone"),
          field: this.$t("excel_headers.timezone"),
          width: 150,
          cellDataType: "text",
          filter: "agTextColumnFilter",
          cellEditor: "Autocomplete",
          cellEditorParams: { values: this.timeZones },
          editable: true,
          cellStyle: params => {
            let result = validateSelect(
              params,
              this.error,
              this.timeZones,
              "Time Zone"
            );
            this.error = result.error;
            if (result.validationStatus != null) {
              params.data["validationStatus"] = result.validationStatus;
            }

            if (result.value == 0) {
              return { backgroundColor: "white" };
            } else if (result.value == 1) {
              return { backgroundColor: "#bdecb6" };
            } else if (result.value == 2) {
              return { backgroundColor: "#FF6961" };
            }
          },
          tooltipValueGetter: params => {
            if (!this.timeZones.includes(params.value)) {
              return this.$t("errors.ND1023");
            }
            if (
              params.data["status"] === "error" &&
              params.data["errorValue"] === params.value
            ) {
              return this.$t("errors." + params.data["errorCode"]);
            }
            return null;
          },
          onCellValueChanged: params => {
            if (params.data["status"] === "error") {
              const row = _.find(this.rowData, {
                rowId: params.data["rowId"]
              });

              if (row) {
                row.status = "modified";
              }
            }
            this.setupColumnDefs();
          }
        },
        {
          headerName: this.$t("admin.excel.user.header_locale"),
          field: this.$t("excel_headers.locale"),
          width: 100,
          cellDataType: "text",
          filter: "agTextColumnFilter",
          cellEditor: "agSelectCellEditor",
          cellEditorParams: { values: this.languages },
          editable: true,
          cellStyle: params => {
            let result = validateSelect(
              params,
              this.error,
              this.languages,
              "Locale"
            );
            this.error = result.error;
            if (result.validationStatus != null) {
              params.data["validationStatus"] = result.validationStatus;
            }

            if (result.value == 0) {
              return { backgroundColor: "white" };
            } else if (result.value == 1) {
              return { backgroundColor: "#bdecb6" };
            } else if (result.value == 2) {
              return { backgroundColor: "#FF6961" };
            }
          },
          tooltipValueGetter: params => {
            if (!this.timeZones.includes(params.value)) {
              return "Lenguage no valido";
            }
            return null;
          }
        },
        {
          headerName: this.$t("admin.excel.user.header_user_phone"),
          field: this.$t("excel_headers.userPhone"),
          width: 150,
          cellDataType: "text",
          filter: "agTextColumnFilter",
          cellStyle: params => {
            let result = validatePhone(params, this.error, "User Phone");
            this.error = result.error;
            if (result.validationStatus != null) {
              params.data["validationStatus"] = result.validationStatus;
            }

            if (result.value == 0) {
              return { backgroundColor: "white" };
            } else if (result.value == 1) {
              return { backgroundColor: "#bdecb6" };
            } else if (result.value == 2) {
              return { backgroundColor: "#FF6961" };
            }
          },
          tooltipValueGetter: params => {
            if (params.value) {
              if (
                params.data["status"] === "error" &&
                params.data["errorValue"] === params.value
              ) {
                return this.$t(params.data["errorCode"]);
              }
            }
            return null;
          },
          onCellValueChanged: params => {
            if (params.data["status"] === "error") {
              const row = _.find(this.rowData, {
                rowId: params.data["rowId"]
              });

              if (row) {
                row.status = "modified";
              }
            }
            this.setupColumnDefs();
          },
          editable: true
        },
        {
          headerName: this.$t("admin.excel.user.header_login"),
          field: this.$t("excel_headers.login"),
          width: 150,
          cellDataType: "text",
          cellStyle: params => {
            let result = validateEmail(
              params,
              this.error,
              this.logins,
              "Login",
              "login"
            );

            this.error = result.error;
            if (result.validationStatus != null) {
              params.data["validationStatus"] = result.validationStatus;
            }

            if (result.exists != null) {
              this.loginExists = result.exists;
            }

            if (result.value == 0) {
              return { backgroundColor: "white" };
            } else if (result.value == 1) {
              return { backgroundColor: "#bdecb6" };
            } else if (result.value == 2) {
              return { backgroundColor: "#FF6961" };
            }
          },
          tooltipValueGetter: params => {
            if (!emailRegex.test(params.value)) {
              return this.$t("errors.ND1041");
            }
            if (this.loginExists) {
              return this.$t("excel.users.email_repeated");
            }
            if (
              params.data["status"] === "error" &&
              params.data["errorValue"] === params.value.toLowerCase()
            ) {
              return this.$t(`errors.${params.data["errorCode"]}`);
            }
            return null;
          },
          onCellValueChanged: params => {
            if (params.data["status"] === "error") {
              const row = _.find(this.rowData, {
                rowId: params.data["rowId"]
              });

              if (row) {
                row.status = "modified";
              }
            }
            this.updateLogins();
            this.setupColumnDefs();
          },
          editable: true
        }
      ];
    },
    send() {
      if (!(this.isSubmitting || this.validated)) {
        this.isSubmitting = true;
        this.responseCount = 0;
        this.errorCount = 0;
        this.successCount = 0;
        const stakeholderCompanyId = localStorage.getItem("managedCompanyId");
        const datetime = () => {
          const selectedDate = new Date(this.date);
          selectedDate.setDate(selectedDate.getDate() - 1);
          const formattedDate = selectedDate.toISOString().split("T")[0];
          return formattedDate;
        };

        const mapData = rowData => {
          return {
            login: !_.isEmpty(rowData["Login"])
              ? rowData["Login"]
              : rowData["User email"],
            name: rowData["Name"],
            surname: rowData["Surname"],
            email: rowData["User email"],
            isBoss: rowData["isManager"],
            stakeholderDepartmentId: this.getStakeHolderId(
              rowData["Department"]
            ),
            phoneNumber: rowData["User Phone"],
            timezone: rowData["Time Zone"],
            locale: rowData["Locale"],
            tags: !_.isEmpty(rowData["Position"]) ? rowData["Position"] : null,
            USER: rowData["Perfil - Usuario"],
            ADMIN: rowData["Perfil - Administrador"],
            CONTROLLER: rowData["Perfil - Operador"],
            rowId: rowData["rowId"],
            dateLaunchSurvey: datetime() + " 21:00:00"
          };
        };

        const sendData = mappedData => {
          return sendExcelUsersData(stakeholderCompanyId, mappedData)
            .then(response => {
              this.responseCount++;
              this.successCount++;
              return response;
            })
            .catch(error => {
              this.responseCount++;
              this.errorCount++;
              return error.response;
            });
        };

        const limit = 50;
        const rowsToSend = _.filter(
          this.rowData,
          row => row.status !== "success"
        );
        const allResponses = [];

        const sendAndProcessBatch = async batch => {
          const batchRequests = batch.map((row, index) => {
            const mappedData = mapData(row, index);
            return sendData(mappedData);
          });

          const batchResponses = await Promise.all(batchRequests);
          allResponses.push(...batchResponses);

          if (allResponses.length === rowsToSend.length) {
            _.each(allResponses, response => {
              if (response.data._meta.status === "ERROR") {
                const item = _.find(this.rowData, {
                  rowId: response.data.records.more.rowId
                });
                if (item) {
                  item.status = "error";
                  item.errorValue = response.data.records.more.value;
                  item.errorMessage = response.data.records.devMessage;
                  item.errorCode = response.data.records.applicationCode;
                }
              } else {
                const item = _.find(this.rowData, {
                  rowId: response.data.records.rowId
                });
                if (item) {
                  item.status = "success";
                }
              }
            });
            if (this.errorCount > 0) {
              this.$swal({
                title: this.$t("admin.excel.warning.title"),
                text:
                  this.$t("admin.excel.warning.success_request") +
                  " " +
                  this.successCount +
                  " " +
                  this.$t("admin.excel.warning.failed_requests") +
                  " " +
                  this.errorCount,
                icon: "warning"
              });
            } else {
              this.$swal
                .fire({
                  title: this.$t("admin.excel.success.title"),
                  text: this.$t("admin.excel.success.subtitle"),
                  icon: "success",
                  showCancelButton: true,
                  confirmButtonText: "OK",
                  cancelButtonText: this.$t("admin.excel.button_redirect")
                })
                .then(result => {
                  if (!result.isConfirmed) {
                    this.loadUsers();
                    this.$router.push({
                      name: "UsersList"
                    });
                  }
                });
            }
            this.validateData(this.rowData);
            this.setupColumnDefs();
          }
        };

        const processAllBatches = async () => {
          const batches = _.chunk(rowsToSend, limit);
          for (const batch of batches) {
            await sendAndProcessBatch(batch);
          }
        };

        processAllBatches()
          .then(() => {
            // console.log("All batches processed:", allResponses);
            this.isSubmitting = false;
          })
          .catch(() => {
            // console.error("Error processing batches:", error);
            this.isSubmitting = false;
          });
      }
    },
    getStakeHolderId(Name) {
      const department = this.departments.find(
        department => department.name === Name
      );
      return department.stakeholderId;
    },
    updateEmails() {
      this.emails = _.map(this.rowData, user => ({
        order: user["rowId"],
        email: user["User email"]
      }));
    },
    updateLogins() {
      this.logins = _.map(this.rowData, user => ({
        order: user["rowId"],
        login: user["Login"]
      }));
    },
    getTomorrowDate() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      const year = tomorrow.getFullYear();
      const month = String(tomorrow.getMonth() + 1).padStart(2, "0");
      const day = String(tomorrow.getDate()).padStart(2, "0");

      const formattedTomorrow = `${year}-${month}-${day}`;

      return formattedTomorrow;
    },
    onGridReady(params) {
      this.gridApi = params.api;
    },
    addRow() {
      const newItem = {
        Name: "",
        Surname: "",
        Department: "",
        isManager: "NO",
        Position: null,
        "User email": null,
        "Perfil - Administrador": "NO",
        "Perfil - Operador": "NO",
        "Perfil - Usuario": "NO",
        "Time Zone": "",
        Locale: "",
        "User Phone": null,
        Login: null,
        rowId: this.rowData.slice(-1)[0].rowId + 1
      };
      this.rowData.push(newItem);
      this.setupColumnDefs();
      this.gridApi.setRowData(this.rowData);
    },
    deleteSelectedRows() {
      const selectedRows = this.gridApi.getSelectedRows();
      this.$swal({
        html: this.$t("admin.popup.delete.multi_user", {
          users: selectedRows.length,
          confirmation: this.$t("admin.popup.delete.delete_confirmation")
        }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0BB783",
        confirmButtonText: this.$t("admin.popup.confirmButton"),
        cancelButtonText: this.$t("admin.popup.cancelButton"),
        input: "text",
        inputValidator: value => {
          if (value === this.$t("admin.popup.delete.delete_confirmation")) {
            selectedRows.forEach(row => {
              const index = this.rowData.findIndex(r => r.rowId === row.rowId);
              if (index !== -1) {
                const filteredArray = this.error.filter(
                  str => !str.startsWith(row.rowId)
                );
                this.error = filteredArray;
                this.rowData.splice(index, 1);
              }
            });
            this.setupColumnDefs();
            this.gridApi.setRowData(this.rowData);
          } else {
            return this.$t("admin.popup.incorrectWord");
          }
        }
      });
    },
    onSelectionChanged() {
      this.selectedRowCount = this.gridApi.getSelectedNodes().length;
    },
    validateData(data) {
      _.each(data, user => {
        this.error = validateTextField(
          {
            value: user["Name"],
            data: {
              rowId: user["rowId"]
            }
          },
          this.error,
          "Name"
        ).error;

        this.error = validateTextField(
          {
            value: user["Surname"],
            data: {
              rowId: user["rowId"]
            }
          },
          this.error,
          "Surname"
        ).error;

        this.error = validateEmail(
          {
            value: user["Login"],
            data: {
              rowId: user["rowId"]
            }
          },
          this.error,
          this.logins,
          "Login",
          "login"
        ).error;

        this.error = validateEmail(
          {
            value: user["User email"],
            data: {
              rowId: user["rowId"]
            }
          },
          this.error,
          this.emails,
          "User Email",
          "email"
        ).error;

        this.error = validateSelect(
          {
            value: user["Department"],
            data: {
              rowId: user["rowId"]
            }
          },
          this.error,
          this.departmentsName,
          "Department"
        ).error;

        this.error = validateBoolean(
          {
            value: user["isManager"],
            data: {
              rowId: user["rowId"]
            }
          },
          this.error,
          "isManager"
        ).error;

        this.error = validateProfile(
          {
            value: user["Perfil - Administrador"],
            data: {
              rowId: user["rowId"]
            }
          },
          this.error,
          "Perfil - Administrador"
        ).error;

        this.error = validateProfile(
          {
            value: user["Perfil - Operador"],
            data: {
              rowId: user["rowId"]
            }
          },
          this.error,
          "Perfil - Operador"
        ).error;

        this.error = validateProfile(
          {
            value: user["Perfil - Usuario"],
            data: {
              rowId: user["rowId"]
            }
          },
          this.error,
          "Perfil - Administrador"
        ).error;

        this.error = validateSelect(
          {
            value: user["Time Zone"],
            data: {
              rowId: user["rowId"]
            }
          },
          this.error,
          this.timeZones,
          "Time Zone"
        ).error;

        this.error = validateSelect(
          {
            value: user["Locale"],
            data: {
              rowId: user["rowId"]
            }
          },
          this.error,
          this.languages,
          "Locale"
        ).error;

        this.error = validatePhone(
          {
            value: user["User Phone"],
            data: {
              rowId: user["rowId"]
            }
          },
          this.error,
          "User Phone"
        ).error;
      });
    }
  },
  mounted() {
    const stakeholderCompanyId = localStorage.getItem("managedCompanyId");
    axios.all([getDepartments(stakeholderCompanyId), getTimezones()]).then(
      axios.spread((responseDepartments, responseTimeZones) => {
        this.departments = responseDepartments.data.records;
        this.timeZones = responseTimeZones.data.records;
        this.languages = this.companySelected.environment.languages.list;
        _.each(this.departments, department => {
          this.departmentsName.push(department.name);
        });
        this.setupColumnDefs();
        const excelData = this.$route.params.excelData;
        if (excelData) {
          _.each(excelData, user => {
            if (!user["Perfil - Usuario"]) {
              user["Perfil - Usuario"] = "NO";
            }
            if (!user["Perfil - Administrador"]) {
              user["Perfil - Administrador"] = "NO";
            }
            if (!user["Perfil - Operador"]) {
              user["Perfil - Operador"] = "NO";
            }
            if (!user["isManager"]) {
              user["isManager"] = "NO";
            }
            if (!user["Login"]) {
              user["Login"] = user["User email"];
            }
            user["Department"] = user["Department"].toUpperCase();
          });
          this.emails = _.map(excelData, user => ({
            order: user["rowId"],
            email: user["User email"]
          }));
          this.logins = _.map(excelData, user => ({
            order: user["rowId"],
            login: user["Login"]
          }));
          this.totalRows = excelData.length;

          this.validateData(excelData);

          this.rowData = excelData;
          this.originalRowData = excelData;
        }
      })
    );
  },
  computed: {
    ...mapGetters("Admin", ["companySelected"]),
    validated() {
      let currentDate = new Date();
      let selectedDate = new Date(this.date);
      if (
        this.error.length > 0 ||
        this.date === null ||
        selectedDate <= currentDate
      ) {
        return true;
      }
      return false;
    },
    dateIsValid() {
      let currentDate = new Date();
      let selectedDate = new Date(this.date);
      if (this.date === null || selectedDate <= currentDate) {
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="scss">
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
</style>
